import React, { useState, useCallback, useEffect } from "react";
import { Stack } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

// const useEvents = (id) => {
//     const [events, setEvents] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const getOrganizationEvents = async (id) => {
//       const response = await fetch("http://localhost:3001/events/" + id, {
//         method: "GET",
//         headers: { "Content-Type": "application/json" },
//       });
//       return response.json();
//     };

//     const fetchEvents = useCallback(async () => {
//       try {
//         const returnedEvents = await getOrganizationEvents(id);
//         // Ensure that returnedEvents is always an array
//         // const eventsArray = Array.isArray(returnedEvents) ? returnedEvents : [];
//         setEvents(returnedEvents);
//         setIsLoading(false);
//       } catch (err) {
//         setError(err);
//         setIsLoading(false);
//       }
//     }, [id]);

//     useEffect(() => {
//       fetchEvents();
//     }, [fetchEvents]);

//     // Debugging: Log events
//     console.log("Events: ", events);

//     return { events, error, isLoading };
//   };

const ManageEvents = (props) => {
  const navigate = useNavigate();

  // const { events, error, isLoading } = useEvents(props.organization._id);
  // const [proposalDisplayed, setProposalDisplayed] = useState("");

  const isLoading = false;
  const error = null;
  const events = [];

  return isLoading ? (
    <div>
      <div style={{ padding: "25px", margin: "50px" }}>
        <center>
          <CircularProgress />
          {error ? (
            <h6>An error occured! We are working hard to get it resolved!</h6>
          ) : (
            <h6>Loading Event Information...</h6>
          )}
        </center>
      </div>
    </div>
  ) : (
    <div className={"h-screen p-8  "}>
      <section>
        <div className="flex justify-between items-center mb-1">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Manage Events</h1>
            <p className="text-gray-500">
              Click view on the proposal you wish to see in PDF format to print
              or download.
            </p>
          </div>
        </div>
        
      </section>

      {/* Conditional rendering based on events.length */}
      {events.length < 1 ? <p>No events at the moment.</p> : <p>Event 1</p>}
    </div>
  );
};

export default ManageEvents;
