import React from "react";

const componentStyle = {
  height: "100px",
  width: "301px",
  background:
    "linear-gradient(82deg, rgba(13, 31, 191, 0.72) -2.94%, #799CF6 101.69%)",
  borderRadius: "10px",
  color: "white",
  padding: "12px 10px 12px 16px",
  display: "flex",
};

const FollowerCountComponent = ({ count, title, }) => {
  return (
    <div className="h-[100px]  bg-gradient-to-r from-[rgba(13,31,191,0.72)] to-[#799CF6] rounded-[10px] text-white p-3 flex ">
      <div className="flex-grow">
        <p className="text-base font-medium text-[#F6F6F6]">Follower Count</p>
        <p className="mt-2 text-2xl font-bold">{3}</p>
      </div>
      <div className="flex-shrink-0">
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    className="text-white opacity-50"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.753 26.4124C8.06502 26.4124 1.49902 27.5744 1.49902 32.2304C1.49902 36.8864 8.02502 38.0904 15.753 38.0904C23.443 38.0904 30.007 36.9264 30.007 32.2724C30.007 27.6184 23.483 26.4124 15.753 26.4124Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7532 19.7718C20.7992 19.7718 24.8892 15.6818 24.8892 10.6358C24.8892 5.58976 20.7992 1.49976 15.7532 1.49976C10.7092 1.49976 6.6192 5.58976 6.6192 10.6358C6.6012 15.6638 10.6612 19.7538 15.6912 19.7718H15.7532Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4072 13.3381V21.3581"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.4988 17.3479H30.3188"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
      </div>
    </div>
  );
};


export default FollowerCountComponent;