import React from 'react'

const TotalRevenueComponent = () => {
  return (
    <div className="h-[100px] bg-gradient-to-r from-[#1786C3] to-[#88CFF7]  rounded-[10px] text-white p-3 flex ">
    <div className="flex-grow">
      <p className="text-base font-medium text-[#F6F6F6]">Total Revenue</p>
      <p className="mt-2 text-2xl font-bold">$ 39,437.76</p>
    </div>
    <div className="flex-shrink-0">
    <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
        >
          <path
            d="M37 25.3V32.7C37 38.94 31.18 44 24 44C16.82 44 11 38.94 11 32.7V25.3C11 31.54 16.82 36 24 36C31.18 36 37 31.54 37 25.3Z"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37 15.3C37 17.12 36.5 18.8 35.62 20.24C33.48 23.76 29.08 26 24 26C18.92 26 14.52 23.76 12.38 20.24C11.5 18.8 11 17.12 11 15.3C11 12.18 12.46 9.36 14.8 7.32C17.16 5.26 20.4 4 24 4C27.6 4 30.84 5.26 33.2 7.3C35.54 9.36 37 12.18 37 15.3Z"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37 15.3V25.3C37 31.54 31.18 36 24 36C16.82 36 11 31.54 11 25.3V15.3C11 9.06 16.82 4 24 4C27.6 4 30.84 5.26 33.2 7.3C35.54 9.36 37 12.18 37 15.3Z"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
    </div>
  </div>
  )
}

export default TotalRevenueComponent