import { React, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import FollowerCountComponent from "./components/FollowerCount";
import TotalEventsComponent from "./components/TotalEvents";
import TotalRevenueComponent from "./components/TotalRevenue";
import YearlyEarningsChart from "./components/YearlyEarningsChart";
import ProfileSection from "./components/ProfileSections";









const Dashboard = (props) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="flex flex-1 h-screen" >
      
      
      
      
      <div className=" p-8 ">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
            <p className="text-gray-500">
              View important information about your organization at a glance.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <TotalRevenueComponent />
          <FollowerCountComponent />
          <TotalEventsComponent />
        </div>
        <YearlyEarningsChart />
        <div className="bg-white rounded-xl p-6 mt-8">
          <h2 className="text-xl font-semibold mb-4">Organization Details</h2>
          <p className="text-gray-600">
            Money Up, Is a fundraising application that is designed to make
            fundraising in schools more efficient and easy to manage. Schools
            can easily track sales numbers, clubs or teams within the school and
            allow administrators, sponsors, student officers, parents and
            consumers to monitor fundraising efforts for their club. Fundraiser
            allows organizations to create proposals for approval, create
            digital tickets, create sale catalogues, send sale links and collect
            money all through one convenient application.
          </p>
        </div>
      </div>


      
      <ProfileSection />
    </div>
  );
};

export default Dashboard;
