import "./App.css";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";


function App() {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="h-full">
      
      <Outlet />
    </div>
  );
}

export default App;
