import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,

} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";



const CreateAccount = ({
    organizationid,
    widgetState,
    handleOpen,
    handleClose,
  }) => {

  
    const registrationSchema = yup.object().shape({
      organizationid: yup.string().required("required"),
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      role: yup.string().required("required"),
      subrole: yup.string().required("required"),
      email: yup.string().email("enter a valid email").required("required"),
      password: yup.string().required("required"),
    });
  
    const initialValuesRegistration = {
      organizationid: organizationid,
      firstName: "",
      lastName: "",
      role: "admin",
      subrole: "",
      email: "",
      password: "",
    };
  
    const createAccount = async (values, onSubmitProps) => {
      const createdAccount = await fetch("http://localhost:3001/auth/signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      const newAccount = await createdAccount.json();
      onSubmitProps.resetForm();
  
      if (newAccount) {
        window.alert("New Account created");
        window.location.reload(true);
      }
    };
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      await createAccount(values, onSubmitProps);
      handleClose();
    };
  
    return (
      <div>
        <button
          style={{
            backgroundColor: "#81B370",
            color: "white",
            borderRadius: "30px",
            fontSize: "12px",
            width: "122px",
            height: "40px",
            borderColor: "transparent",
          }}
          onClick={handleOpen}
        >
          Create Account
        </button>
        <Dialog open={widgetState} onClose={handleClose}>
          <DialogTitle>Create Account</DialogTitle>
  
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesRegistration}
            validationSchema={registrationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <DialogContentText>
                    Create an account and assign the type of account.
                  </DialogContentText>
  
                  <TextField
                    disabled
                    margin="dense"
                    id="organization_id"
                    label="Organization ID - Read Only"
                    defaultValue={organizationid}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    id="firstName"
                    label="First Name"
                    onBlur={handleBlur("firstName")}
                    onChange={handleChange("firstName")}
                    value={values.firstName}
                    error={
                      Boolean(touched.firstName) && Boolean(errors.firstName)
                    }
                    helperText={touched.firstName && errors.firstName}
                  />
  
                  <TextField
                    fullWidth
                    variant="standard"
                    id="lastName"
                    label="Last Name"
                    onBlur={handleBlur("lastName")}
                    onChange={handleChange("lastName")}
                    value={values.lastName}
                    error={
                      Boolean(touched.lastName) && Boolean(errors.lastName)
                    }
                    helperText={touched.lastName && errors.lastName}
                  />
  
                  <TextField
                    fullWidth
                    variant="standard"
                    id="email"
                    label="Email"
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    value={values.email}
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    id="subrole"
                    label="Subrole"
                    onBlur={handleBlur("subrole")}
                    onChange={handleChange("subrole")}
                    value={values.subrole}
                    error={Boolean(touched.subrole) && Boolean(errors.subrole)}
                    helperText={touched.subrole && errors.subrole}
                  />
                  {/* <div className="mt-4">
                    <Autocomplete
                      value={values.role}
                      onChange={(e, value) => {
                        setFieldValue("role", value);
                        setRole(value);
                      }}
                      id="role"
                      options={options}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Role" />
                      )}
                    />
                  </div> */}
                  
                </DialogContent>
  
                <DialogTitle>Login Credentials</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Do not share your credentials.
                  </DialogContentText>
  
                  <TextField
                    fullWidth
                    variant="standard"
                    id="email"
                    label="Email"
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    value={values.email}
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    id="password"
                    label="Password"
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    value={values.password}
                    error={Boolean(touched.password) && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </DialogContent>
                <DialogActions>
                  <button
                    onClick={handleClose}
                    style={{
                      backgroundColor: "white",
                      color: "#595959",
                      width: "104px",
                      height: "40px",
                      borderRadius: "30px",
                      borderColor: "#81B370",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#81B370",
                      color: "white",
                      border: "none",
                      width: "104px",
                      height: "40px",
                      borderRadius: "30px",
                    }}
                  >
                    Create
                  </button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  };

export default CreateAccount