import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import CreateAccount from "./components/CreateAccount";
import AccountTile from "./components/AccountTile";

const ManageAccounts = (props) => {
  // const navigate = useNavigate();
  // const organization = useSelector((state) => state.organization);
  // // const { accounts, isLoading, error } = useAccounts(organization._id);
  const [accountModalOpen, openAccountCreation] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const isLoading = false;

  const handleAcccountDelete = async(userid) => {

    const response = await fetch("http://localhost:3001/users/delete/" + userid, 
     {
      method: "DELETE",
      headers: {"Content-Type" : "application/json"},
     
     });
     if (response) {
      window.alert("Account has been deleted");
      window.location.reload(true);
    }
  };

  return (
    <div className="h-screen p-8 ">
      <section>
      <div className="flex justify-between items-center mb-1">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Manage Admin Accounts</h1>
            <p className="text-gray-500">
            Manage accounts and users for your organization
            </p>
          </div>
          <CreateAccount
              organizationid={user.permissions.managing}
              widgetState={accountModalOpen}
              handleOpen={() => openAccountCreation(true)}
              handleClose={() => openAccountCreation(false)}
            />
        </div>
      </section>
      {/* account grid below */}
      {isLoading ? (
        <div>
          <center>
            <h3>Loading Information ...</h3>
          </center>
        </div>
      ) : (
        <section>
          <div style={{ width: "100%" }}></div>
        </section>
      )}
      <div
        style={{
          color: "black",
          height: "50px",
          backgroundColor: "transparent",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          lineHeight: "70px",
          fontSize: "14px",
          padding: "0px 26px",

          textAlign: "left",
        }}
      >
        <div style={{ display: "inline-block", width: "250px" }}>
          Account ID
        </div>

        <div style={{ display: "inline-block", width: "170px" }}>
          <p>Full Name</p>
        </div>

        <div style={{ display: "inline-block", width: "300px" }}>
          <p>Email</p>
        </div>

        <div style={{ display: "inline-block", width: "125px" }}>
          <p>Subrole</p>
        </div>

        {/* <div style={{ display: "inline-block", width: "250px" }}>
          <p>Assigned Group</p>
        </div> */}

        <div style={{ alignItems: "flex-end", width: "240px" }}>
          <p>More</p>
        </div>
      </div>

      {/* {props.organization.administrators.map((account, index) => (
        <AccountTile account={account} />
      ))} */}
    </div>
  );
};

//CREATE ACCOUNT FUNCTION

export default ManageAccounts;
