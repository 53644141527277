import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";

const ManageGroups = () => {
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [expandedEvent, setExpandedEvent] = useState(null);

  const groups = [
    {
      id: "63bcd2c9dddbddb343afd",
      name: "Raider Band",
      events: [
        {
          id: "1",
          name: "New York Trip",
          details: {
            proposalId: "63bcd2c9dddbddb343afd",
            title: "New York Trip",
            fundraiserType: "Chocolate Sales",
            status: "Active",
            description:
              "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatuQuis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu",
            startDate: "2023-01-30T22:38:54Z",
            endDate: "December 31, 2023",
          },
        },
        { id: "2", name: "Six Flags Trip", details: {} },
        { id: "3", name: "Disney Trip", details: {} },
      ],
    },
  ];

  const toggleGroup = (groupId) => {
    setExpandedGroup(expandedGroup === groupId ? null : groupId);
    setExpandedEvent(null);
  };

  const toggleEvent = (eventId) => {
    setExpandedEvent(expandedEvent === eventId ? null : eventId);
  };

  return (
    <div className="p-8 h-screen">
      <section>
        <div className="flex justify-between items-center mb-1">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">
              Manage Groups
            </h1>
            <p className="text-gray-500">
            Manage Groups/Clubs and their information
            </p>
          </div>
          <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
            Add Group
          </button>
        </div>
      </section>
      

      {groups.map((group) => (
        <div key={group.id} className="mb-4 border rounded-md">
          <div
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => toggleGroup(group.id)}
          >
            <h2 className="text-lg font-medium">{group.name}</h2>
            {expandedGroup === group.id ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </div>
          {expandedGroup === group.id && (
            <div className="p-4 bg-gray-50">
              <p className="text-sm text-gray-600 mb-4">
                unique Group ID : {group.id}
              </p>
              <h3 className="font-medium mb-2">Events</h3>
              <p className="text-sm text-gray-600 mb-2">
                Choose an event to view information
              </p>
              {group.events.map((event) => (
                <div key={event.id} className="mb-2">
                  <div
                    className="flex justify-between items-center p-2 bg-white rounded-md cursor-pointer"
                    onClick={() => toggleEvent(event.id)}
                  >
                    <span>{event.name}</span>
                    {expandedEvent === event.id ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                  {expandedEvent === event.id && (
                    <div className="mt-2 p-4 bg-white rounded-md border">
                      <div className="flex justify-between items-start mb-4">
                        <h4 className="font-medium">Events Summary</h4>
                        <button onClick={() => setExpandedEvent(null)}>
                          <CloseIcon size={20} />
                        </button>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="font-medium">Proposal ID</p>
                          <p className="text-sm text-gray-600">
                            {event.details.proposalId}
                          </p>
                        </div>
                        <div>
                          <p className="font-medium">Title</p>
                          <p className="text-sm text-gray-600">
                            {event.details.title}
                          </p>
                        </div>
                        <div>
                          <p className="font-medium">Fundraiser Type</p>
                          <p className="text-sm text-gray-600">
                            {event.details.fundraiserType}
                          </p>
                        </div>
                        <div>
                          <p className="font-medium">Status</p>
                          <p className="text-sm text-green-600">
                            {event.details.status}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="font-medium">Description</p>
                        <p className="text-sm text-gray-600 mt-1">
                          {event.details.description}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <p className="font-medium">Start Date</p>
                          <p className="text-sm text-gray-600">
                            {event.details.startDate}
                          </p>
                        </div>
                        <div>
                          <p className="font-medium">End Date</p>
                          <p className="text-sm text-gray-600">
                            {event.details.endDate}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-end space-x-2">
                        <button className="px-4 py-2 border rounded-md hover:bg-gray-50">
                          View Tickets
                        </button>
                        <button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                          View Proposal
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <button className="text-gray-600 hover:underline mt-2">
                View Older Events
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ManageGroups;
