// organizationReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organization: null,
  isLoading: true,
  error: null,
};

// Reducer
const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.organization = action.payload.organization;
      // state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default organizationSlice.reducer;
