import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// const ctx = document.getElementById('myChart').getContext('2d');




const yearlyEarningsData = [
  { month: "Jan", value: 840.2 },
  { month: "Feb", value: 840.2 },
  { month: "Mar", value: 203.0 },
  { month: "Apr", value: 920.12 },
  { month: "May", value: 882.0 },
  { month: "Jun", value: 810.0 },
  { month: "Jul", value: 910.0 },
  { month: "Aug", value: 47.0 },
  { month: "Sep", value: 910.0 },
  { month: "Oct", value: 433.2 },
  { month: "Nov", value: 240.0 },
  { month: "Dec", value: 910.0 },
];





const YearlyEarningsChart = () => (
  <div className="bg-white rounded-xl p-6 mt-8">
    <h2 className="text-xl font-semibold mb-4">Yearly Earnings</h2>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={yearlyEarningsData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#4CAF50"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
);


export default YearlyEarningsChart