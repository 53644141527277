//index.js
import reportWebVitals from './reportWebVitals';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from './pages/auth/LoginPage';
import AdminPanel from './pages/admin';
import Dashboard from './pages/admin/Dashboard';
import ManageGroups from './pages/admin/ManageGroups';
import ManageEvents from './pages/admin/ManageEvents';
import ManageAccounts from './pages/admin/ManageAccounts';
import ManageProposals from './pages/admin/ManageProposals';

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage/>
  },
  {
    path: "/app",
    element: <App />,
    children: [
      
      {
        path: "admin",
        element: <AdminPanel />,
        children: [
          {
            path: "dashboard",
            element: <Dashboard text={"dashboard"} />,
          },
          {
            path: "accounts",
            element: <ManageAccounts text={"dashboard"} />,
          },
          {
            path: "events",
            element: <ManageEvents text={"dashboard"} />,
          },
          
          {
            path: "groups",
            element: <ManageGroups text={"dashboard"} />,
          },
          {
            path: "proposals",
            element: <ManageProposals text={"dashboard"} />,
          },
          
        ],
      },

    ]
  },

  
  
]);




root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
    <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);


reportWebVitals();
