import React from "react";

const TotalEventsComponent = (props) => {
  return (
    <div className="h-[100px] bg-gradient-to-r from-[#600DBF] to-[#DE88F7]  rounded-[10px] text-white p-3 flex ">
      <div className="flex-grow">
        <p className="text-base font-medium text-[#F6F6F6]">Total Events</p>
        <p className="mt-2 text-2xl font-bold">186</p>
      </div>
      <div className="flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="40"
          viewBox="0 0 34 40"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.4754 1.52362H9.16936C5.00936 1.50762 1.59936 4.82362 1.50136 8.98162V30.4076C1.40936 34.6336 4.75936 38.1356 8.98536 38.2296C9.04736 38.2296 9.10736 38.2316 9.16936 38.2296H25.1474C29.3354 38.0596 32.6354 34.5996 32.6054 30.4076V12.0756L22.4754 1.52362Z"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.9502 1.5V7.318C21.9502 10.158 24.2462 12.46 27.0862 12.468H32.5962"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.5759 26.717H10.7759"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4859 19.212H10.7739"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default TotalEventsComponent;
