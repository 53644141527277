import { React, useEffect, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { routes } from "./routes";
import { useSelector } from "react-redux";
import SideNavigationBar from "../../common/SideNavigationBar";

const Admin = () => {
  const useOrganization = (id) => {
    const getOrganization = async ({ role, id, token }) => {
      const response = await fetch(
        `http://localhost:3001/organizations/${role}/${id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.json();
    };

    const [organization, setOrganization] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchOrganization = useCallback(async () => {
      await getOrganization(id)
        .then((returnedOrganization) => {
          console.log(returnedOrganization);
          setOrganization(returnedOrganization);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
        });
    }, [id]);

    useEffect(() => {
      fetchOrganization();
    }, []);

    return { organization, isLoading, error };
  };

  const { user } = useSelector((state) => state.auth);

  const { organization, isLoading, error } = useOrganization({
    role: user.permissions.role,
    id: user.permissions.managing,
  });

  return  (
    <div className="flex"
    >
      <SideNavigationBar data={routes} />
      <div className="h-screen w-full bg-gray-100"  >
        
        <Outlet/>
      </div>
    </div>
  );
};

export default Admin;


