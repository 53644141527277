import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../store/actions/authActions';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

const LoginForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    validationSchema={yup.object({
      email: yup.string().email('Invalid email').required('Required'),
      password: yup.string().required('Required'),
    })}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="w-full max-w-md">
        <h1 className="text-3xl font-bold mb-2">Welcome to Money-Up! 👋</h1>
        <p className="text-gray-600 mb-6">Please sign in to your account and start the adventure</p>
        
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email or Username</label>
          <Field type="text" id="email" name="email" placeholder="john.doe" className="w-full px-3 py-2 border border-gray-300 rounded-md" />
          <ErrorMessage name="email" component="div" className="text-red-600 text-sm" />
        </div>
        
        <div className="mb-4">
          <div className="flex justify-between items-center mb-1">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <a href="#" className="text-sm text-green-600 hover:underline">Forgot Password?</a>
          </div>
          <Field type="password" id="password" name="password" className="w-full px-3 py-2 border border-gray-300 rounded-md" />
          <ErrorMessage name="password" component="div" className="text-red-600 text-sm" />
        </div>
        
        <div className="flex items-center mb-4">
          <Field type="checkbox" id="remember" name="remember" className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded" />
          <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">Remember Me</label>
        </div>
        
        <button type="submit" className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-300" disabled={isSubmitting}>Sign In</button>
      </Form>
    )}
  </Formik>
);

const StatsCard = ({ title, value, change, period }) => (
  <div className="bg-white p-4 rounded-lg shadow-md">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-500 text-sm mb-1">{period}</p>
    <div className="flex items-center justify-between">
      <span className="text-3xl font-bold">{value}</span>
      <span className={`text-sm ${change.startsWith('+') ? 'text-green-500' : 'text-red-500'}`}>
        {change}
      </span>
    </div>
  </div>
);

const LoginPage = () => {
  const url = 'http://localhost:3001';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signIn = async (values) => {
    const response = await fetch(url + '/auth/signin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    });
    const data = await response.json();
    console.log('signIn response:', data); // Debugging
    return data;
  };

  const handleSignIn = async (values, { setSubmitting, resetForm }) => {
    try {
      const loggedIn = await signIn(values);
      console.log('loggedIn:', loggedIn); // Debugging
      if (loggedIn && loggedIn.user && loggedIn.user.permissions) {
        const role = loggedIn.user.permissions.role;
        resetForm();
        setSubmitting(false);

        dispatch(setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        }));
        console.log(`Navigating to /${role}-panel/dashboard`); // Debugging
        navigate(`/${role}-panel/dashboard`);
      } else {
        console.error('Invalid login response:', loggedIn); // Debugging
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Login error:', error); // Debugging
      setSubmitting(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="flex-1 flex items-center justify-center p-8">
        <LoginForm onSubmit={handleSignIn} />
      </div>
      <div className="hidden lg:flex flex-1 bg-green-500 items-center justify-center p-8 relative">
        <div className="absolute top-0 right-0 w-24 h-24 bg-green-600 rounded-bl-full"></div>
        <div className="absolute bottom-0 left-0 w-24 h-24 bg-green-400 rounded-tr-full"></div>
        <div className="text-white text-center max-w-lg">
          <h2 className="text-4xl font-bold mb-4">Turn your ideas into reality</h2>
          <p className="mb-8">Lorem ipsum dolor sit amet consectetur. Sed vitae at dui pulvinar pharetra nulla sociis. Hac</p>
          <div className="grid grid-cols-2 gap-4">
            <StatsCard title="Profit" value="624k" change="+8.24%" period="Last Month" />
            <StatsCard title="View" value="124k" change="+12.6%" period="Last week" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
