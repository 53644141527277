import React from "react";
import { OrganizationLinkSVG } from "../../../../assets/OrganizationLinkSVG";


const ProfileSection = () => (
    <div className="profile ml-auto w-[330px] bg-white p-7">
      <div className="flex flex-col">
        
        <h4 className="text-2xl text-gray-600">My Profile</h4>
  
  
        <p className="text-sm text-gray-500 font-normal mb-6">
          <span className="text-[#759E69] font-medium">85%</span> Profile Completed
        </p>
  
        <center>
          <div className="w-[160px] h-[160px] bg-[#F6F7FB] rounded-full"></div>
          <p className="mt-10 text-sm font-normal text-gray-500">Signed in as:</p>
          <h6 className="mt-4 mb-20 text-sm font-normal">halvarez@ouitrend.com</h6>
        </center>
        
        
        <div className="bg-[#F6F7FB] items-end w-[275px] rounded-lg p-2.5">
          <h6>Organization Link</h6>
          <div className="my-2.5 py-5 px-4">
            <OrganizationLinkSVG />
          </div>
          <div className="bg-white whitespace-normal my-2.5 py-5 px-4 break-words text-xs">
            https://www.domain.com/organization/63b381c44f999b5454b09b4e
          </div>
          <div className="rounded-full bg-[#7BAA6C] my-2.5 py-2.5 px-4 text-white text-xs text-center">
            Copy Link
          </div>
        </div>
  
  
      </div>
    </div>
  );

  export default ProfileSection