import HomeOutlined from '@mui/icons-material/HomeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export const routes = [
    {
        id: 0,
        icon: <HomeOutlined sx={{ color: "#759E69"}}/>,
        text: "Dashboard",
        link: "dashboard"
    },
    {
        id: 1,
        icon: <ManageAccountsOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Manage Accounts",
        link: "accounts"
    },
    {
        id: 2,
        icon: <AssignmentOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Manage Events",
        link: "events"
    },
    {
        id: 3,
        icon: <GroupsOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Manage Groups",
        link: "groups"
    },    
    {
        id: 4,
        icon: <AccountBalanceOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Billing Information",
        link: "billing"
    },
    {
        id: 5,
        icon: <ArticleOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Manage Proposals",
        link: "proposals"
    },
    {
        id: 6,
        icon: <AssessmentOutlinedIcon sx={{ color: "#759E69"}}/>,
        text: "Reports",
        link: "reports"
    },
    
    

]