import { React, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./styles.css"
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLogout } from "../../store/actions/authActions";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

const SideNavigationBar = ({ data }) => {
  const user = useSelector((state) => state.user);

  const [open, setopen] = useState(false);
  const [ind, setInd] = useState(0)

  const toggleOpen = () => {
    setopen(!open);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();




  const logout = () => {
    dispatch(setLogout());
    navigate("/");
  };

  const handleClick = (link, index) => {
    setInd(index);
    navigate(link);
  }

  return (
    <div
      className={open ? "sidenav": "sidenavClosed"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
       
      }}
    >
      <div>
        <button className={open ? "openedMenuBtn" : "closedMenuBtn"} onClick={toggleOpen}>
          {open ? (
            <KeyboardDoubleArrowLeftIcon sx={{color: "#585858"}}/>
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </button>

        <List>
          {data.map((item, index) => {
            return (
              <ListItem
                disablePadding
                id={item.id}
               

                style={
                  index === ind
                    ? { alignSelf: "center", color: "#759E69" }
                    : { alignSelf: "center", color: "#ACAEB9" }
                }
              >
                <ListItemButton onClick={() => handleClick( item.link, index)}>
                  <ListItemIcon sx={{color: "red"}}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    className={open ? true : "linkClosed"}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <List>
          <ListItem
            disablePadding
            style={{ alignSelf: "center", color: "#759E69" }}
          >
            <ListItemButton onClick={() => navigate("account")}>
              <ListItemIcon>
                <PersonOutlinedIcon style={{ color: "#759E69" }} />
              </ListItemIcon>
              <ListItemText
                primary={"My Account"}
                className={open ? true : "linkClosed"}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            style={{ alignSelf: "center", color: "#759E69" }}
          >
            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <LogoutOutlinedIcon style={{ color: "#759E69" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Logout"}
                className={open ? true : "linkClosed"}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default SideNavigationBar;
